<template>
    <div class="login-box">
        <div class="login-logo">
            <img src="@/assets/logo.svg" alt="ANASAP Logo">
        </div>
        <!-- /.login-logo -->
        <div class="login-box-body">
            <p class="login-box-msg">Použitý ověřovací link bohužel není platný.</p>
            <p class="login-box-msg">Buďto vypršela jeho platnost nebo je nějakým způsobem poškozen.</p>
            <form>
            <div class="row">
                <div class="col-xs-12 col-sm-6 mb-2">
                <a href="/#/register"><button type="button" class="anasap-btn">Registrovat</button></a>
                </div>
                <div class="col-xs-12 col-sm-6 anafra-margin">
                <a href="/#/login"><button type="button" class="anasap-btn">Přihlásit</button></a>
                </div>
                <!-- /.col -->
            </div>
            </form>

        </div>
        <!-- /.login-box-body -->
    </div>
</template>

<script>
const name = 'InvalidLink';

export default {
    name
}
</script>

<style>
    @import "../assets/scss/login.scss";

    .mb-2 {
        margin-bottom: 1rem;
    }

    @media (min-width: 768px) {
        .login-box .row div:nth-child(2) button {
            margin-left: auto;
        }
    }
</style>
